import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {ApplicationConfig, provideZoneChangeDetection} from '@angular/core'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {provideRouter, withHashLocation} from '@angular/router'

import {routes} from './app.routes'

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withHashLocation()),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        // authInterceptor,
        // responseInterceptor
      ])
    )
  ]
}
